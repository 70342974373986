/*
--- 01 TYPOGRAPHY SYSTEM
- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Line heights
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6
- Letter spacing
-0.5px
0.75px
--- 02 COLORS

- Primary: #D46415
- Tints: d46415	bf5a13	aa5011	94460f	7f3c0d	6a320b
- Shades:  d46415	d8742c	dd8344	e1935b	e5a273	eab28a	eec1a1	f2d1b9	 #fbf0e8


- Gold: #ffd700
- Tints: ffd700	e6c200	ccac00	b39700	998100	806c00
- Shades:  ffd700	ffdb1a	ffdf33	ffe34d	ffe766	ffeb80	ffef99	

- Accents:
- Greys
#f8f9fa
#767676 (lightest grey allowed on #fff)
#6f6f6f (lightest grey allowed on #fdf2e9)
#555
#333

--- 07 WHITESPACE
- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
linear-gradient(
      rgba(34, 34, 34, 0.6),
      rgba(34, 34, 34, 0.6)
    ),
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #f8f9fa;
}
/* ----------------------- */
/*           GENERAL        */
/* ----------------------- */

.GTV {
  color: #ffd700;
  font-size: 4rem;
  font-weight: 600;
}

a {
  display: inline-block;
  text-decoration: none;
}

.main-btn:link,
.main-btn:visited {
  background-color: #d46415;
  /* color: #ffe34d; */
  color: #f8f9fa;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  font-size: 1.6rem;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  letter-spacing: 0.5px;
  vertical-align: middle;
  user-select: none;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease-in-out;
  opacity: 1;
}

.main-btn:hover,
.main-btn:active {
  background-color: #aa5011;
  /* color: #ffd700; */
  box-shadow: inset 0 0 3px 2px #ffe34d;
  transform: scale(1.1, 1.1);
  opacity: 1;
}

.btnMobileNav {
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 2rem;
  display: none;
}

.ButtonContactUs {
  text-align: center;
}

.NavIcon {
  color: #f8f9fa;
  font-size: 4.2rem;
}

.NavIcon[name="close-outline"] {
  display: none;
}

.SectionHidden {
  opacity: 0;
  transform: translateY(8rem);
}
/* ----------------------- */
/*           HEADER        */
/* ----------------------- */

.header-container {
  /* background-color: rgba(229, 162, 115, 0.1); */
  background-color: white;
  padding-bottom: 7rem;
  background-color: rgb(249, 232, 220, 0.2);
  background-color: #f5d9c5;
}

header {
  background-image: linear-gradient(
      to right,
      rgba(34, 34, 34, 0.5),
      rgba(34, 34, 34, 0.3)
    ),
    url("../public/assets//home1.webp");

  background-size: cover;
  border-bottom-left-radius: 15rem;
  height: 100vh;
  /* display: grid;
  grid-template-rows: auto 1fr; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  /* position: relative; */
  overflow-x: hidden;
}

/* ----------------------- */
/*           HERO        */
/* ----------------------- */

.hero {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
}

.hero-text-box {
  display: flex;
  flex-direction: column;
  /* padding: 0 10rem 10rem 10rem; */
  padding-left: 10rem;
  margin-bottom: 3rem;
  align-items: flex-start;
  row-gap: 3rem;
  align-self: center;
  justify-self: center;
  letter-spacing: 0.1rem;
}

.heading-primary {
  font-size: 3rem;
  line-height: 1.3;
  font-weight: 600;
}

.heading-secondary {
  font-size: 2rem;
  line-height: 1.5;
}

/* ----------------------- */
/*           ABOUT US       */
/* ----------------------- */

.about-us {
  /* background-color: white; */
  /* background-color: rgba(249, 232, 220, 0.2); */
  background-image: linear-gradient(#f5d9c5, #f9e8dc);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding-top: 7rem;
  padding-bottom: 14rem;
}

.about-us-img {
  background-image: url("../public/assets//home2.jpg");
  background-size: cover;
  border-top-right-radius: 15rem;
  border-bottom-right-radius: 15rem;
  max-width: 80rem;
}

.about-us-textbox {
  display: flex;
  color: rgb(56, 66, 77);
  flex-direction: column;
  row-gap: 4.6rem;
  align-items: flex-start;
  margin: 0 8rem 0 8rem;
  padding: 3.2rem;
  /* border: 2px solid;
  border-color: #ffd700; */
  /* border-top-left-radius: 5rem;
  border-bottom-right-radius: 5rem; */
  border-radius: 3rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.about-us-contact {
  row-gap: 3.6rem;
}

.about-us-primary {
  align-self: center;
  font-size: 3.6rem;
  line-height: 1.3;
  font-weight: 600;
}

.about-us-secondary {
  color: rgba(52, 58, 64, 0.7);
  line-height: 3rem;
  font-size: 1.8rem;
}

.about-us-contact {
  color: rgba(52, 58, 64, 0.7);
  line-height: 3rem;
  font-size: 1.8rem;
}

/* ---------------------------- */
/*      Services               */
/* ---------------------------- */

.Services {
  padding-top: 7rem;
  padding-bottom: 14rem;
  background-color: rgb(249, 232, 220, 0.2);
  background-image: linear-gradient(
    rgb(245, 217, 197, 0.5),
    rgb(253, 247, 243, 0.5)
  );
}

.servicesIcons {
  display: flex;
  flex-wrap: wrap;
  margin: 0 8rem 0 8rem;
  justify-content: space-around;
}

.iconContainer {
  padding: 3rem;
  text-align: center;
  border-radius: 5px;
  max-width: 26rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
}

.serviceIcon {
  color: #ffd700;
  --ionicon-stroke-width: 16px;
  font-size: 8em;
  padding: 2rem;
  margin: 2rem;
  border: 3px solid #ffd700;
  border-radius: 50%;
  /* flex: 0 0 40%; */
}

.ServicePrimary {
  color: rgb(56, 66, 77);
  line-height: 3rem;
  font-size: 2.4rem;
  font-weight: 500;
}

.ServiceSecondary {
  color: rgb(56, 66, 77);
  line-height: 2.8rem;
  font-size: 2rem;
  font-weight: 400;
}

.iconContainer:hover {
  background-color: #ffd700;
}

.iconContainer:hover .serviceIcon {
  color: white;
  border: 3px solid white;
}

.iconContainer:hover .ServiceSecondary {
  color: white;
}

.iconContainer:hover .ServicePrimary {
  color: white;
}

/* ---------------------------- */
/*      footer               */
/* ---------------------------- */

.footer {
  background-color: rgb(34, 34, 34, 0.95);
  padding: 10rem;
}

.footer-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: start;
  justify-content: center;
  grid-column-gap: 5rem;
}
.footer-divider {
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: space-around;
}
.footer-quicklink h4 {
  text-align: stretch;
}

.footer-quicklink-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  align-items: stretch;
}

.footer-el {
  display: flex;
  align-items: center;
}

.footer-header {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  line-height: 1.2;
}

.footer-adress p {
  font-size: 1.5rem;
  margin-top: 2rem;
}

.footerIcon {
  font-size: 2em;
  margin: 2rem;
}

.contactIcon {
  font-size: 2rem;
  margin-right: 1rem;
  color: rgb(56, 66, 77);
}

.footer-el p {
  font-size: 1.5rem;
  color: #f8f9fa;
  /* line-height: 3.2rem;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: left; */
}

.footer-nav-link:link,
.footer-nav-link:visited {
  display: flex;
  align-items: center;
  text-decoration: none;
  /* color: #ffd700; */
  color: #f8f9fa;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 2.4rem;
}

.footer-nav-link:hover,
.footer-nav-link:active {
  /* border-bottom: 0.3rem solid;
  border-color: #d46415; */
  box-shadow: 0 4px 2px -2px #d46415;
  /* font-weight: 500; */
}

/* Contact */
.Contact-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  align-items: stretch;
}

.Contact-el {
  display: flex;
  align-items: center;
}

.contactSecondary {
  color: rgb(56, 66, 77);
  line-height: 3.2rem;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: left;
}
